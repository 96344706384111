import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import Accordion from '@ingka/accordion';
import Button from '@ingka/button';
import chevronDoubleLeftSmall from '@ingka/ssr-icon/paths/chevron-double-left-small';
import chevronDoubleRightSmall from '@ingka/ssr-icon/paths/chevron-double-right-small';
import { OM_FILTERS_EXPAND_COLLAPSE_BUTTON__CLICK } from 'types/analytics';
import { getSelectedCaption, getSelectedCCCaption } from 'components/OMFilters/helpers';
import { t } from 'i18next';
import useData from 'hooks/useData';
import { CheckBox } from 'types/appContext';
import { useSearchParams } from 'react-router-dom';
import { OMFilter as OMFilterCategory } from 'types/enums';
import useAuth from 'hooks/useAuth';
import useApp from 'hooks/useApp';
import classes from './OMFilters.module.scss';
import OMFilter from './OMFilter';
import OMFilterSort from './OMFilterSort';

export enum SortOrder { BY_NAME = '0', BY_ID = '1' }

const OMFilters = memo(() => {
    const MIN_WIDTH: number = 254;
    const COLLAPSED_WIDTH: number = 54;
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [sidebarWidth, setSidebarWidth] = useState<number>(MIN_WIDTH);
    const [showExpandedOMFilter, setShowExpandedOMFilter] = useState<boolean>(true);
    const { currentCountry } = useData();
    const { access } = useAuth();
    // Can be set for simultaneously sorting multiple OM filters by name or id
    const [searchParams] = useSearchParams();
    const [omSortOrder, setOmSortOrder] = useState<string>(searchParams.get('omSortOrder') ?? '');
    const hasDepartmentsViewAccess = access?.features?.departmentsView(currentCountry) ?? false;
    const {
        updateOrgSelection,
        orgSelection: { selections: { countrySelection, unitSelection, divisionSelection, costCentreSelection, departmentSelection } },
    } = useApp();

    const toggleExpandFilter = () => {
        setShowExpandedOMFilter(!showExpandedOMFilter);
        if (showExpandedOMFilter) {
            setSidebarWidth(COLLAPSED_WIDTH);
        } else {
            setSidebarWidth(MIN_WIDTH);
        }
    };

    const startResizing = useCallback((event: any) => {
        if (event.type === 'mousedown') {
            setIsResizing(true);
        }
    }, []);

    const stopResizing = useCallback((event: MouseEvent) => {
        if (event.type === 'mouseup') {
            setIsResizing(false);
        }
    }, []);

    const resize = useCallback(
        (event: MouseEvent) => {
            if (showExpandedOMFilter && event.type === 'mousemove' && isResizing && sidebarRef.current) {
                if (event.clientX > MIN_WIDTH) {
                    setSidebarWidth(event.clientX - sidebarRef.current.getBoundingClientRect().left);
                }
            }
        },
        [isResizing, showExpandedOMFilter]
    );

    const sortCCByToggleIndex = (current: CheckBox, next: CheckBox) => {
        if (omSortOrder === SortOrder.BY_ID) {
            return Number(current.subLabel) - Number(next.subLabel);
        }
        if (omSortOrder === SortOrder.BY_NAME) {
            return current.label.localeCompare(next.label);
        }

        return 0;
    };

    useEffect(() => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);

        return () => {
            window.removeEventListener('mousemove', resize);
            window.removeEventListener('mouseup', stopResizing);
        };
    }, [resize, stopResizing]);
    /**
     * Sorting cost centers in OM filters by name or id
     */
    useEffect(() => {
        const omso = searchParams.get('omSortOrder');
        if (omso) {
            setOmSortOrder(omso || '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return (
        <>
            <div
                data-testid="om-filters"
                className={classes.sidebar}
                style={{ width: sidebarWidth }}
                ref={sidebarRef}
                onMouseDown={e => e.preventDefault()}
            >
                <aside
                    className={showExpandedOMFilter ? classes['om-filters'] : classes['om-filters-closed']}
                >

                    {showExpandedOMFilter && (
                    <div className={classes['om-filters-accordion']}>
                        <Accordion size="large">
                            <div>
                                <OMFilter
                                    data={countrySelection}
                                    type={OMFilterCategory.COUNTRY}
                                    handleChange={updateOrgSelection}
                                    title={t('COUNTRY')}
                                    selectionType="single"
                                    caption={getSelectedCaption(countrySelection)}
                                    autoCollapse
                                />
                                <OMFilter
                                    data={unitSelection}
                                    type={OMFilterCategory.UNIT}
                                    handleChange={updateOrgSelection}
                                    title={t('UNIT')}
                                    selectionType="single"
                                    caption={getSelectedCaption(unitSelection)}
                                    autoCollapse
                                />
                                <OMFilter
                                    data={divisionSelection}
                                    type={OMFilterCategory.DIVISION}
                                    handleChange={updateOrgSelection}
                                    title={t('BUSINESS_FUNCTIONS')}
                                    selectionType="multi"
                                    caption={getSelectedCaption(divisionSelection, t('SELECTED'))}
                                    showSelectAll={(divisionSelection && divisionSelection?.length > 1)}
                                />
                                <OMFilter
                                    data={costCentreSelection?.sort(sortCCByToggleIndex)}
                                    type={OMFilterCategory.COST_CENTER}
                                    handleChange={updateOrgSelection}
                                    title={t('COST_CENTRES')}
                                    selectionType="multi"
                                    caption={getSelectedCCCaption(costCentreSelection, t('SELECTED'))}
                                    showSelectAll={costCentreSelection && costCentreSelection?.length > 1}
                                    sortingToggle={(
                                        <OMFilterSort
                                            toggleLabels={[{ text: t('A_Z') }, { text: '0-9' }]}
                                            param="omSortOrder"
                                        />
                                    )}
                                />
                                {hasDepartmentsViewAccess
                                && (
                                <OMFilter
                                    data={departmentSelection}
                                    type={OMFilterCategory.DEPARTMENT}
                                    handleChange={updateOrgSelection}
                                    title={t('DEPARTMENTS')}
                                    selectionType="multi"
                                    caption={getSelectedCaption(departmentSelection, t('SELECTED'))}
                                    showSelectAll={departmentSelection && departmentSelection?.length > 1}
                                />
                                )}
                            </div>
                        </Accordion>
                    </div>
                    )}
                </aside>
                <div className={classes['om-filters-collapse-button']}>
                    <Button
                        iconOnly
                        size="small"
                        type="tertiary"
                        ssrIcon={showExpandedOMFilter ? chevronDoubleLeftSmall : chevronDoubleRightSmall}
                        onClick={toggleExpandFilter}
                        data-analytics={OM_FILTERS_EXPAND_COLLAPSE_BUTTON__CLICK}
                    />
                </div>
            </div>
            <div className={classes['resize-handle']} id="resize-handle" data-target="aside" onMouseDown={startResizing} />
        </>
    );
});

export default OMFilters;
