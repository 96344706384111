import React, { BaseSyntheticEvent, useState, useCallback } from 'react';
import { ScenarioListItem } from 'types/scenario';
import InputField from '@ingka/input-field';
import Button from '@ingka/button';
import Trash from '@ingka/ssr-icon/paths/trash-can';
import Star from '@ingka/ssr-icon/paths/star';
import StarFilled from '@ingka/ssr-icon/paths/star-filled';
import { useUpdateScenarioMainStateAPI } from 'hooks/api/useUpdateScenarioMainStateAPI';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { useDeleteScenarioAPI } from 'hooks/api/useDeleteScenarioAPI';
import { useUpdateScenarioNameAPI } from 'hooks/api/useUpdateScenarioNameAPI';
import useData from 'hooks/useData';
import { DEBOUNCE_TIME_LONG } from 'types/enums';
import useApp from 'hooks/useApp';
import { MAIN_SCENARIO_SYMBOL, OTHER_SCENARIO_SYMBOL } from 'utils/constants';
import classes from './EditableScenario.module.scss';
import RemoveMainScenarioNavigationPanel from './RemoveMainScenarioNavigationPanel';

interface EditableScenarioProps {
    scenario: ScenarioListItem,
    displayToast: ({ title, message }: { title: string, message: string }) => void,
}

const EditableScenario = ({ scenario, displayToast }: EditableScenarioProps) => {
    const [activeStep, setActiveStep] = useState<1 | 2>(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputFieldValue, setInputFieldValue] = useState<string>(scenario.name);
    const { t } = useTranslation('translation', { keyPrefix: 'toolbar.editableScenario' });
    const { access } = useAuth();
    const { deleteScenario } = useDeleteScenarioAPI(scenario.id);
    const { updateScenarioName } = useUpdateScenarioNameAPI(scenario.id);
    const { updateScenarioMainState } = useUpdateScenarioMainStateAPI();
    const { handleAnalytics } = useApp();
    const { currentUnit, scenarioList, setScenarioList, currentScenario, selectScenario, setScenarioBudget, getScenarios } = useData();

    const hasDeleteScenarioAccess = access?.api?.deleteScenario;
    const hasEditScenarioRights = access?.api?.editScenario;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleRenameScenario = useCallback(_.debounce(async (newName: string) => {
        if (!scenarioList?.scenarios?.length) {
            return;
        }

        if (scenarioList.scenarios.some(s => s.name === newName)) {
            displayToast({ title: t('error'), message: t('scenarioNameExistsSelectNew') });

            return;
        }

        if (newName.includes(MAIN_SCENARIO_SYMBOL) || newName.includes(OTHER_SCENARIO_SYMBOL)) {
            displayToast({ title: t('error'), message: t('scenarioNameForbiddenCharacter') });

            return;
        }

        updateScenarioName({
            name: newName
        }).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('error'), message: t('operationFailed') });

                return;
            }
            displayToast({ title: t('success'), message: t('scenarioUpdated') });

            getScenarios();
        });
    }, DEBOUNCE_TIME_LONG, { trailing: true }), [scenarioList]);

    const handleChange = (event: BaseSyntheticEvent) => {
        setInputFieldValue(event?.target?.value);
        if (event?.target?.value === '') {
            // Cancel the debounced post request if the input is empty
            handleRenameScenario.cancel();

            return;
        }
        if (event?.target?.value === scenario.name) {
            // Cancel the debounced post request if the input the same as the current name
            handleRenameScenario.cancel();

            return;
        }

        handleRenameScenario(event.target.value);
    };

    const handleDeleteScenario = _.debounce(() => {
        if (!scenarioList?.scenarios?.length) {
            return;
        }

        deleteScenario().then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('error'), message: t('operationFailed') });
            } else {
                displayToast({ title: t('success'), message: t('scenarioDeleted') });
            }

            setScenarioList(
                {
                    scenarios: [
                        ...scenarioList.scenarios.filter(scenList => Number(scenList.id) !== Number(scenario.id)),
                    ]
                }
            );

            if (Number(scenario.id) === Number(currentScenario?.id)) {
                selectScenario('noScenario');
                setScenarioBudget(false);
            }
        }).catch(() => {
            displayToast({ title: t('error'), message: t('operationFailed') });
        });
    }, 500, { trailing: true });

    const activeScenarioMainState = _.debounce(() => {
        if (!scenarioList?.scenarios?.length || scenario.isMain) {
            return;
        }

        updateScenarioMainState({
            id: `${scenario.id}`,
            isMain: true
        }).then(response => {
            if (response.error) {
                displayToast({ title: t('error'), message: t('operationFailed') });
            } else {
                displayToast({
                    title: t('success'),
                    message: t('scenarioIsNowMain', { scenarioName: scenario.name, unitCode: currentUnit })
                });
                setScenarioList(
                    {
                        scenarios: [
                            ...scenarioList.scenarios.map(item => {
                                const scenarioItem = item;
                                if (item.id === scenario.id) {
                                    scenarioItem.isMain = true;
                                } else {
                                    scenarioItem.isMain = false;
                                }

                                return item;
                            }),
                        ]
                    }
                );
                setTimeout(() => {
                    if (handleAnalytics) {
                        handleAnalytics();
                    }
                }, 0);
            }
        }).catch(() => {
            displayToast({ title: t('error'), message: t('operationFailed') });
        });
    }, 500, { trailing: true });

    return (
        <div key={scenario.id} className={classes.scenario}>
            <InputField
                autoComplete="off"
                id="manage-scenario-form"
                data-testid="manage-scenario-form-input"
                value={inputFieldValue}
                type="text"
                onChange={handleChange}
            />
            <Button
                onClick={() => {
                    if (scenario.isMain) {
                        setIsModalOpen(true);
                    } else {
                        setActiveStep(2);
                    }
                }}
                type="tertiary"
                size="small"
                iconOnly
                ssrIcon={Trash}
                disabled={!hasDeleteScenarioAccess}
                data-testid={`manage-scenario-delete-button-${scenario.id}`}
            />
            <Button
                onClick={activeScenarioMainState}
                type="tertiary"
                size="small"
                iconOnly
                ssrIcon={scenario.isMain ? StarFilled : Star}
                data-testid={`manage-scenario-activate-main-scenario-button-${scenario.id}`}
                disabled={!hasEditScenarioRights}
            />
            <ConfirmationModal
                isOpen={activeStep === 2}
                title={t('scenarioDeletionPrompt')}
                cancelButtonText={t('cancel')}
                confirmButtonText={t('yesRemove')}
                content={t('scenarioDeletionDesc', { scenarioName: scenario.name })}
                handleCancel={() => { setActiveStep(1); }}
                handleConfirm={handleDeleteScenario}
            />

            <span>
                <RemoveMainScenarioNavigationPanel
                    deletedScenario={scenario}
                    modalVisibility={isModalOpen}
                    setModalVisibility={setIsModalOpen}
                />
            </span>
        </div>
    );
};

export default EditableScenario;
